<template>
    <div>
        <ts-page-title
            :title="$t('loanPaidOffByCo.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('loanPaidOffByCo.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="row">
                    <div class="col-md-3">
                        <div class=" card tw-border-0 bg-gray-900">
                            <div class="card-body">
                                <div class="text-gray-500">
                                    <label class="form-label col-label">{{
                                        $t('loanPaidOffByCo.filterCriteria')
                                    }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="widget-list rounded-bottom">
                            <div class="mb-3 mt-3">
                                <div class="col-md-12">
                                    <label
                                        class="form-label col-label required"
                                        >{{
                                            $t('loanPaidOffByCo.classifiedBy')
                                        }}</label
                                    >
                                    <br />
                                    <RadioGroup
                                        v-model="model.classified_by"
                                        @on-change="model.employee_id = []"
                                        size="small"
                                    >
                                        <Radio
                                            class="mt-1"
                                            :label="c.value"
                                            border
                                            v-for="(c, index) in classified"
                                            :key="index"
                                        >
                                            {{ c.label }}
                                        </Radio>
                                    </RadioGroup>
                                </div>
                                <div class="mb-3 mt-3">
                                    <div class="col-md-12">
                                        <Checkbox v-model="model.new_client"
                                            >New Client
                                        </Checkbox>
                                    </div>
                                </div>
                                <div class="mb-3 mt-3">
                                    <div class="col-md-12">
                                        <label class="form-label col-label">{{
                                            $t('loanPaidOffByCo.branch')
                                        }}</label>
                                        <ts-branch-filter
                                            @filter="
                                                value =>
                                                    (model.branch_id = value)
                                            "
                                            :isPlaceholder="true"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="mb-3"
                                    v-if="model.classified_by == 'co'"
                                >
                                    <div class="col-md-12">
                                        <label
                                            class="form-col-label control-label"
                                            >{{
                                                $t('loanPaidOffByCo.co')
                                            }}</label
                                        >
                                        <Select
                                            v-model="model.employee_id"
                                            multiple
                                            :max-tag-count="1"
                                            :filterable="true"
                                            :filter-by-label="true"
                                            :placeholder="$t('all')"
                                        >
                                            <Option
                                                v-for="item in employees"
                                                :value="item.employee_id"
                                                :key="item.employee_id"
                                                :label="item.employee_name_en"
                                            >
                                                {{ item.employee_name_en }}
                                            </Option>
                                        </Select>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="col-md-12">
                                        <label
                                            class="form-col-label control-label"
                                            >{{
                                                $t(
                                                    'loanPaidOffByCo.actualPaymentDate'
                                                )
                                            }}</label
                                        >
                                        <DatePicker
                                            v-model="model.dateRange"
                                            type="daterange"
                                            placement="bottom-end"
                                            style="width: 100%"
                                            :transfer="true"
                                            format="dd-MM-yyyy"
                                            @on-change="onChangeDate"
                                        ></DatePicker>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="col-md-12">
                                        <label
                                            class="form-col-label control-label required"
                                            >{{
                                                $t('loanPaidOffByCo.currency')
                                            }}</label
                                        ><br />
                                        <RadioGroup
                                            v-model="model.currency_id"
                                            size="small"
                                        >
                                            <Radio
                                                class="mt-1"
                                                :label="c.currency_id"
                                                border
                                                v-for="(c, index) in currencies"
                                                :key="index"
                                            >
                                                {{ c.currency_code }}
                                            </Radio>
                                        </RadioGroup>
                                    </div>
                                </div>
                                <div
                                    class="mb-3 tw-justify-end tw-flex tw-space-x-2"
                                >
                                    <ts-button
                                        outline
                                        color="success"
                                        @click.prevent="exportExcel"
                                        :waiting="exporting"
                                    >
                                        <i
                                            class="far fa-file-excel"
                                            v-if="!exporting"
                                        ></i>
                                        {{ $t('exportExcel') }}</ts-button
                                    >
                                    <ts-button
                                        color="danger"
                                        outline
                                        @click.prevent="preview"
                                        :waiting="waiting"
                                    >
                                        <i
                                            class="far fa-file-pdf"
                                            v-if="!waiting"
                                        ></i>
                                        {{ $t('previewPdf') }}</ts-button
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div id="container">
                            <ts-preview-pdf v-model="waiting" :src="src" />
                        </div>
                    </div>
                </div>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { debounce } from 'lodash'
import moment from 'moment'
import { Errors } from 'form-backend-validation'
import * as FileDownload from 'downloadjs'
import { trim } from 'lodash'

export default {
    name: 'loanPaidOffByCo',
    data () {
        return {
            waiting: false,
            exporting: false,
            errors: new Errors(),
            src: '',
            model: {
                classified_by: 'co',
                currency_id: null,
                employee_id: [],
                branch_id: [],
                new_client: false,
                dateRange: [
                    moment()
                        .startOf('month')
                        .format('DD-MM-YYYY'),
                    moment()
                        .endOf('month')
                        .format('DD-MM-YYYY')
                ]
            },
            classified: [
                {
                    value: 'co',
                    label: 'Credit Officer'
                },
                {
                    value: 'branch',
                    label: 'Branch'
                }
            ]
        }
    },
    computed: {
        ...mapState('report/resource', ['employees', 'currencies'])
    },
    created () {
        this.model.currency_id = this.$store.state.authUser.sys_setting[0].default_currency_id
    },
    methods: {
        ...mapActions('report/resource', ['getEmployee', 'getCurrency']),
        preview () {
            this.errors = new Error()
            this.waiting = true
            this.src = ''
            this.$store
                .dispatch('report/creditOperation/loanPaidOffByCo', this.model)
                .then(response => {
                    this.src = response.url
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                    this.error = new Errors(error.errors)
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        exportExcel () {
            this.errors = new Errors()
            this.exporting = true
            this.$store
                .dispatch(
                    'report/creditOperation/loanPaidOffByCoExportExcel',
                    this.model
                )
                .then(response => {
                    let filename = trim(
                        response.headers['content-disposition']
                            .substring(
                                response.headers['content-disposition'].indexOf(
                                    'filename'
                                )
                            )
                            .replace('filename=', ''),
                        '"'
                    )
                    FileDownload(response.data, filename)
                })
                .catch(err => {
                    let error = JSON.parse(
                        String.fromCharCode.apply(null, new Uint8Array(err))
                    )
                    this.notice({ type: 'error', text: error.message })
                    this.errors = new Errors(error.errors)
                })
                .finally(() => (this.exporting = false))
        },
        onChangeDate (dateRange) {
            this.model.dateRange = dateRange[0] && dateRange[1] ? dateRange : []
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'LOAN PAID-OFF',
                desc: not.text
            })
        }
    },
    watch: {
        'model.branch_id': debounce(function (value) {
            this.model.employee_id = []
            this.getEmployee({
                branch_id: value
            })
        }, 500)
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.getCurrency()
        })
    }
}
</script>
